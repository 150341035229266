<template>
  <div class="legal-text-wrapper">
    <div id="Panes">
      <p>
        Die Künstler weisen darauf hin, dass sie ohne Begründung Bilder
        zurückweisen können, die erkennbar Rechte Dritter verletzen, gegen
        Gesetze verstoßen, rassistisch, propagandistisch oder antidemokratisch
        sind. Die Einsteller der Bilder versichern, dass die Fotografien frei
        von Rechten Dritter sind und die Teilnahme an dem Projekt nicht
        gewerblich motiviert ist.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.legal-text-wrapper {
  width: 100%;
  height: 100%;
  padding: 1em;
  overflow-x: none;
  overflow-y: scroll;
  background-color: inherit;
  color: inherit;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 2em;
    margin-top: 1em;
  }
}
</style>
