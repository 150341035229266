<template>
  <svg class="rotate-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 71">
  <g id="Group_3" data-name="Group 3" transform="translate(170.076 -247.622) rotate(39)">
    <g id="Ellipse_3" data-name="Ellipse 3" transform="translate(44 283)" fill="none" stroke="#1c2020" stroke-width="2">
      <circle cx="20" cy="20" r="20" stroke="none"/>
      <circle cx="20" cy="20" r="19" fill="none"/>
    </g>
    <rect id="Rectangle_10" data-name="Rectangle 10" width="20" height="20" transform="translate(64 303)" fill="#c2c1b8"/>
    <path id="Polygon_1" data-name="Polygon 1" d="M5,0l5,8H0Z" transform="translate(88 309) rotate(180)" fill="#1c2020"/>
  </g>
  <text id="rotieren" transform="translate(30 67)" fill="#252b2b" font-size="16px" font-family="MuseoSans-500, 'Museo Sans \35 00'" font-weight="500"><tspan x="-30" y="0">rotieren</tspan></text>
</svg>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.rotate-button{
    font-size: 16px;
    flex-shrink: 0;
    height: 4.5rem;
    padding-right: 2rem;
}
</style>